'use client';

import React, { createContext, useContext, useMemo, useRef } from 'react';
import { createIntl, createIntlCache } from '@formatjs/intl';

const LocalizationContext = createContext(null);

const cache = createIntlCache();

interface Props {
  children: React.JSX.Element;
  languages: any;
  messages: any;
}

const LocalizationsProvider = ({ children, languages, messages }: Props) => {
  const intl = useRef(
    createIntl({ locale: languages.locale, messages }, cache)
  );

  const value = useMemo(() => ({ translations: intl.current }), []);

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

function useLocalizations() {
  const context = useContext(LocalizationContext);
  if (!context) throw new Error('useLocalization requires a context!');

  return context;
}

export { LocalizationsProvider, useLocalizations };
