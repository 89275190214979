'use client';

import React, {
  CSSProperties,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { ToastOptions, toast } from 'react-hot-toast';

import { useNotifications, useSchemes } from '@personly/libs-providers';
import { Toast } from '@personly/libs-widgets';
import { deploymentUrl } from '@personly/libs-urls';
import { toPathname } from '@personly/libs-pathnames';

const Toaster = lazy(() =>
  import('react-hot-toast').then((module) => ({ default: module.Toaster }))
);

const ToastBar = lazy(() =>
  import('react-hot-toast').then((module) => ({ default: module.ToastBar }))
);

const onNotify = async (locale, handle, substitutions = {}) => {
  const pathnames = ['translate', locale, handle];

  const url = deploymentUrl(toPathname(pathnames.join('/')));

  for (const key of Object.keys(substitutions)) {
    url.searchParams.set(key, substitutions[key]);
  }

  const response = await fetch(url);
  const { result } = await response.json();

  return result ? result.value : handle;
};

interface Props {
  children: React.ReactNode;
  locale: string;
}

const Notifications = ({ children, locale }: Props) => {
  const { cssVariables } = useSchemes();
  const { removeNotification, notifications } = useNotifications();

  const style = useMemo<CSSProperties>(
    () => ({
      backgroundColor: cssVariables.toastBackgroundColor,
      border: `1px solid ${cssVariables.toastBorderColor}`,
      color: cssVariables.toastTextColor,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'normal',
      padding: 0,
    }),
    [
      cssVariables.toastBackgroundColor,
      cssVariables.toastBorderColor,
      cssVariables.toastTextColor,
    ]
  );

  useEffect(() => {
    (async () => {
      for (const notification of notifications) {
        const options: ToastOptions = {
          position: 'top-center',
          id: notification.handle,
          duration: notification.duration,
          style,
        };

        const message = await onNotify(
          locale,
          notification.handle,
          notification.substitutions
        );

        if (notification.type === 'NOTICE') {
          toast(message, options);
        } else if (notification.type === 'SUCCESS') {
          toast.success(message, options);
        } else if (notification.type === 'ERROR') {
          toast.error(message, options);
        }

        if (notification.duration !== Infinity) {
          setTimeout(() => {
            removeNotification(notification.handle, () => {});
          }, notification.duration);
        }
      }
    })();
  }, [notifications, removeNotification, style, locale]);

  const dismiss = useCallback(
    (id) => removeNotification(id, () => toast.dismiss(id)),
    [removeNotification]
  );

  return (
    <Suspense>
      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <Toast
                dismiss={() => dismiss(t.id)}
                message={message}
                type={t.type}
              >
                {children}
              </Toast>
            )}
          </ToastBar>
        )}
      </Toaster>
    </Suspense>
  );
};

export default Notifications;
