import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/components/body/index.tsx\",\"import\":\"Krub\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"]}],\"variableName\":\"krub\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsProvider"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/notifications.js");
;
import(/* webpackMode: "eager", webpackExports: ["SuspensionsProvider"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/suspensions.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/components/notifications/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/errorCode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationsProvider"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/localizations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/schemes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StepsProvider","useSteps"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/steps.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ValidatorsProvider","useValidators"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/validators.tsx");
